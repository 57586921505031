<template>
  <div class="flex flex-col justify-center relative py-1.5 px-3 bg-custom-gray-10 mb-4">
    <div class="flex flex-row justify-between">
      <!-- submission task buttons starts -->
      <div
        v-if="submissionFormMode === 'EDIT'
          && ['Inflight'].includes(submissionForm.submissionStatus)
          && (!isUserRestricted || submissionForm.currentStepName === 'Licensee')"
        class="flex flex-row"
      >
        <icon-button
          title="Claim Task(s)"
          class="mr-1 w-7.5 h-7.5"
          icon="icons/downloadSimple.svg"
          :active="claimTaskButtonActive"
          :is-loading="claimTaskLoading"
          active-icon-class="text-indigo-600"
          inactive-btn-class="cursor-not-allowed"
          @click="taskActions('claim')"
        />
        <icon-button
          title="Unclaim Task(s)"
          class="mr-1 w-7.5 h-7.5"
          icon="icons/thumbsDown.svg"
          :active="unclaimTaskButtonActive"
          :is-loading="claimTaskLoading"
          active-icon-class="text-custom-orange-1"
          inactive-btn-class="cursor-not-allowed"
          @click="taskActions('unclaim')"
        />
        <icon-button
          v-if="!isUserRestricted"
          title="Assign Task(s)"
          class="mr-1 w-7.5 h-7.5"
          icon="icons/share.svg"
          :active="assignButtonActive"
          :is-loading="claimTaskLoading"
          active-icon-class="text-emerald-600"
          inactive-btn-class="cursor-not-allowed"
          @click="openAssignTaskModal('assign')"
        />
        <icon-button
          v-if="!isUserRestricted"
          title="Re-assign Task(s)"
          class="mr-1 w-7.5 h-7.5"
          icon="icons/arrowBendDoubleUpRight.svg"
          :active="reAssignButtonActive"
          :is-loading="claimTaskLoading"
          active-icon-class="text-cyan-700"
          inactive-btn-class="cursor-not-allowed"
          @click="openAssignTaskModal('reassign')"
        />
      </div>
      <!-- submission task buttons ends -->
      <!-- jump to section starts -->
      <div class="py-1 flex flex-row justify-end">
        <span class="font-semibold text-sm mx-4">
          Jump To:
        </span>
        <span
          v-scroll-to="'#licenseeDetails'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Licensee Details
        </span>
        <span
          v-scroll-to="'#submissionDetails'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Submission Details
        </span>
        <span
          v-if="submissionForm.safetyDocIncluded === 'Yes'"
          v-scroll-to="'#safetyDocuments'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Safety
        </span>
        <span
          v-if="submissionForm.physicalSampleIncluded === 'Yes'"
          v-scroll-to="'#sampleDetails'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Sample Details
        </span>
        <span
          v-if="submissionForm.returnSample === 'Yes'"
          v-scroll-to="'#returnSampleDetails'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Return Sample Details
        </span>
        <span
          v-if="!['Generic', 'Print on Demand Art'].includes(submissionForm.submissionTypeName)"
          v-scroll-to="'#characters'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Characters
        </span>
        <span
          v-if="!['Generic', 'Press Releases, Blogs, Media Alerts, any other PR', 'Print on Demand Art'].includes(submissionForm.submissionTypeName)"
          v-scroll-to="'#skuSection'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          SKU
        </span>
        <span
          v-scroll-to="'#attachedDocuments'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Upload
        </span>
        <span
          v-scroll-to="'#comments'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Comments
        </span>
        <span
          v-scroll-to="'#bottom'"
          class="font-semibold text-sm text-primary-red mx-4 cursor-pointer hover:text-black"
        >
          Bottom of Page
        </span>
      </div>
      <!-- jump to section ends -->
    </div>
    <!-- assign task modal -->
    <brand-assurance-assign-tasks-modal
      v-model="showAssignTaskModal"
      :mode="assignTaskModalMode"
      @assignUser="handleAssigningTask($event)"
      @hide="setAssignTaskModalVisibility(false)"
    />
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import { useStore } from 'vuex';
import useSubmissionForm from './submissionForm.js';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SUCCESS, TASK_ACTION_FAILED, TASK_ACTION_SUCCESS } from '@/constants/alerts.js';

export default {
    name: 'JumpToSection',

    components: {
        IconButton: defineAsyncComponent(() => import('@/components/IconButton.vue')),
        BrandAssuranceAssignTasksModal: defineAsyncComponent(() => import('@/components/BrandAssuranceAssignTasksModal.vue'))
    },

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { submissionForm, submissionFormMode, updateSubmissionForm, hasSubmissionAccess } = useSubmissionForm();

        const doesUserHaveAssociatedLicensees = computed(() => store.getters['auth/doesUserHaveAssociatedLicensees']);
        const isReadOnlyUser = computed(() => store.getters['auth/isReadOnlyUser']);
        const isUserRestricted = computed(() => {
            const isUserRestricted = doesUserHaveAssociatedLicensees.value || isReadOnlyUser.value;
            console.log('isUserRestricted: ', isUserRestricted);
            console.log('doesUserHaveAssociatedLicensees: ', doesUserHaveAssociatedLicensees.value);
            return isUserRestricted;
        });

        const currentUser = computed(() => store.getters['auth/getUserId']);

        // Task Button status
        const claimTaskButtonActive = computed(() =>
            !submissionForm.assignUser &&
          !!submissionForm.currentStepName &&
          hasSubmissionAccess(submissionForm.currentStepName)
        );
        const unclaimTaskButtonActive = computed(() =>
            !!submissionForm.assignUser &&
          !!submissionForm.currentStepName &&
          hasSubmissionAccess(submissionForm.currentStepName)
        );
        const assignButtonActive = computed(() => claimTaskButtonActive.value);
        const reAssignButtonActive = computed(() => unclaimTaskButtonActive.value);

        const claimTaskLoading = ref(false);
        const updatedSubmissionsTasks = computed(() => store.getters['baSubmissions/getSubmissionTask']);

        const taskActions = async (action, newAssignee) => {
            try {
                if (claimTaskLoading.value) {
                    return;
                }
                claimTaskLoading.value = true;
                const submissions = [];

                const submissionsObj = {
                    submissionId: submissionForm.submissionId,
                    taskId: submissionForm.taskId,
                    currentStepName: submissionForm.currentStepName,
                };

                submissions.push(submissionsObj);

                const bodyPayload = {
                    submissions,
                    action
                };

                if (action === 'claim') {
                    newAssignee = currentUser.value;
                }

                if (action !== 'unclaim') {
                    bodyPayload.assignUser = newAssignee;
                }

                try {
                    await store.dispatch('baSubmissions/submissionTasks', { bodyPayload });
                } catch (claimErr) {
                    return notificationsStack.value.push({
                        type: ERROR,
                        message: 'The submission details are not valid. Please close this form and try again.'
                    });
                }

                // updating assignee after submission tasks api is resolved
                store.dispatch('baSubmissions/updateAssigneeInSubmissionsList', {
                    newSubmissions: updatedSubmissionsTasks.value
                });
                store.dispatch('baSubmissions/updateAssigneeInOpenedSubmission', {
                    submission: updatedSubmissionsTasks.value[0]
                });
                updateSubmissionForm({ assignUser: newAssignee || null });
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: TASK_ACTION_SUCCESS
                });
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: TASK_ACTION_FAILED
                });
            } finally {
                claimTaskLoading.value = false;
            }
        };

        // assign/re-assign task modal logic
        const assignTaskModalMode = ref('');
        const showAssignTaskModal = ref(false);
        const setAssignTaskModalVisibility = (modalVisibility) => {
            showAssignTaskModal.value = modalVisibility;
        };
        const handleAssigningTask = (newAssignee) => {
            taskActions(assignTaskModalMode.value, newAssignee);
        };
        const openAssignTaskModal = (mode) => {
            assignTaskModalMode.value = mode;
            setAssignTaskModalVisibility(true);
        };

        return {
            submissionForm,
            submissionFormMode,
            // submission action button logic
            claimTaskButtonActive,
            claimTaskLoading,
            taskActions,
            unclaimTaskButtonActive,
            assignButtonActive,
            reAssignButtonActive,
            // assign/re-assign task modal
            showAssignTaskModal,
            setAssignTaskModalVisibility,
            assignTaskModalMode,
            openAssignTaskModal,
            handleAssigningTask,
            isUserRestricted
        };
    }
};
</script>
